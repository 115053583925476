const appearanceLanguageArabic = {

   //side bar main 
   Discover:"يكتشف",
   Jobs:"وظائف",
   Candidates:"مرشحين",
   Reports:"التقارير",
   Help:"يساعد",





  Appearance: "مظهرإعدادات المظهر",
  Appearance_Description: "إن تخصيص مساحة العمل الخاصة بك يجعل العمل أكثر متعة وراحة!",
  Custom_theme: "إنشاء موضوع مخصص",
  Inter_face_theme: "موضوع الوجه المشترك",
  Inter_face_theme_Description: "اختر موضوع ألوانك المفضل من هنا",
  System_light_PurpleBlue: "نظام - ضوء",
  System_Dark_PurpleBlue: "نظام - مظلم",
  System_light_Pink: "نظام -  الوردي",
  Sidebar_feature: "ميزة الشريط الجانبي",
  Sidebar_feature_Description: "ما يظهر في الشريط الجانبي لسطح المكتب.",
  Recent_used_apps: "التطبيقات المستخدمة مؤخرا",
  Language: "لغة",
  Language_Description: "إعدادات اللغة",
  Select_Language: "اختار اللغة",
  Select_Language_Description: "اختر لغتك من القائمة المنسدلة",
  English: "إنجليزي",
  Show_Arabic_in_table_fields: "إظهار اللغة العربية في حقول الجدول",
  Show_Arabic_in_table_fields_Description: "سيؤدي هذا إلى إظهار اللغة العربية في حقول الجدول عند إنشاء العناصر وتحريرها",
  Enable: "يُمكَِن",
  Enable_Description: "سيتم تعطيل وظيفة شريط الأوامر.. lorem ipsum",
  Search_Command_Bar: "بحث & شريط الأوامر",
  Search_Command_BarDescription: "استخدم هذه القائمة المنسدلة للتوسيع",
  Show_Suggestion: "عرض الاقتراح",
  Show_Suggestion_Description: "سيؤدي هذا إلى عرض الاقتراحات باللغة العربية",
  Disable_Voice_Comma: "تعطيل الفاصلة الصوتية",
  Disable_Voice_Comma_Description: "التحكم بالأوامر الصوتية هنا",


  //side bar 
  Settings:"إعدادات",
  General:"عام",
  Appearance:"مظهر",
  Notification:"إخطار",
  Other:"آخرين",
  Role_and_Privileges:" الدور والامتيازات",
  Company:"شركة",
  Team_members:"أعضاء الفر  يق",
  System_settings:"اعدادات النظام",
  Integrations:"التكامل",
  Templates:"قوالب",


  //comon

  comon_Dscription:"ينسق التخطيط والتنفيذ والانتهاء من المشاريع...",
};
export default appearanceLanguageArabic;
