import joblist from "./Jobs/English";
import appearance from "./Settings/Appearance/English";
import TemplateLanguage from "./Templates/English";
import otherLanguageArabic from "./Other/English"


const englishLanguage = {
  ...joblist,
  ...appearance,
  ...TemplateLanguage,
  ...otherLanguageArabic
};

export default englishLanguage;
