const otherLanguageEnglish = {
  Email_notification: "Email Notification",
  Email_Notification_description: "Description",
  Salary_Slip: "Salary Slip",
  Salary_Certificate: "Salary Certificate",
  Gratuity_Approval: "Gratuity Approval",
  Loan_approvals: "Loan Approvals",
  Subtitle_Notfication:
    "This will show Arabic in table fields when creating and editing items.",
  notification_dsc:
    "Coordinates the planning, execution, and completion of projects...",

  Role_and_Privileges: "Role and Privileges",
  Roles_Management: "Roles Management",
  Attendance_Access: "Attendance Access",
  Access_Settings: "Access Settings",
  Role_List: "Role List",
  Users_List: "Users List",
  Action: "Action",
  Status: "Status",
  Employees: "Employees",
  Roles: "Roles",
  Create_Role: "Create Role",

  Departments: "Departments",
  Locations: "Locations",
  Name: "Name",
  Description: "Description",

  Team_Members: "Team Members",
  Contact: "Contact",
  Image: "Image",
  Add_Team_Member: "Add Team Member",

  System_Settings: "System Settings",
  Listing_all_system: "Listing all system config",
  Listing_All_description: "lorem ipsum dummy text dolar sit.",
  Prefix: "Prefix",
  Job_Code_Format: "Job Code Format",
  Filters: "Filters",
  Sort_by: "Sort by",

  Main_Description:
    "Coordinates the planning, execution and completion of the projects",
  System_Settings: "System Settings",
  Integration: "Integration",

  Templates: "Templates",
  Letter: "Letter",
  Questionnaire: "Questionnaire",
  Evaluation: "Evaluation",
  Email: "Email",
  Workflow: "Workflow",
  Job_Description: "Job Description",
  Job_Templates: "Job Templates",
  Name: "Name",
  Description: "Description",
  Status: "Status",
};
export default otherLanguageEnglish;
