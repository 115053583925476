const appearanceLanguage = {
     Appearance: "Appearance",
     Jobs: "Jobs",
     My_Open_Jobs: "My Open Jobs",
     All_Jobs: "All Jobs",
     Applied: "Applied",
     Open: "Open",
     Draft: "Draft",
     Type: "Type",
     Location: "Location",
     Posted_By: "Posted By",
     Date: "Date",

     Total_No_of_Jobs_Posted: "Total No of Jobs Posted",
     Source_Diversity: "Source Diversity",
     Open_Jobs: "Open Jobs",
     Rejection_Average: "Rejection Average",
     Hired_Count: "Hired Count",


     Source: "Source",
     Stage: "Stage",
     Applied_Date: "Applied Date",


     Add_Candidate: "Add Candidate",
     Personal_Details: "Personal_Details",
     Educational_Details: "Educational_Details",
     Work_Experience: "Work_Experience",
     Review: "Review",


     Head_Of_Director: "Head of Director",
     Head_of_director: "Head of Director",
     help: "help",
     Personal_Details: "Personal Details",
     Prefix: "Prefix",
     First_Name: "First Name",
     Last_Name: "Last Name",
     Phone_number: "Phone Number",
     City_Or_Town: "City or Town",
     Address_Line: "Address Line",
     Postal_Code: "Postal Code",
     Create_a_Job: "Create a Job",

     //All Jobs

     Job_Details: "Job Details",
     Application_Form: "Application Form",
     Workflow: "Workflow",
     Team_Member: "Team Member",
     Publish: "Publish",





};
export default appearanceLanguage;
